.adminpage .content {
    padding: 20px;
    display: flex;
    justify-items: right;
    text-align: right;
}

.adminpage.logo-container {
    text-align: center;
    background-color: transparent;
    float: none;
    margin: auto;
    width: 100%;
  }

  
.adminpage div.entries {
    width: fit-content;
    margin-left: 20px;
}

.adminpage .entries ul li {
    margin-bottom: 10px;
}

.adminpage .entries ul li a.active-entry {
    font-weight: bold;
}

.adminpage li span.entry-id {
    display: none;
}

.adminpage li:hover  span.entry-id {
    display: block;
    z-index: 15;
    direction: ltr;
}

.adminpage div.admin div.focus {
    min-width: 60%;
    max-width: 80%;
}

.adminpage button.button-link {
    background-color: transparent;
    color: blue;
    font-weight: normal;
    padding: 0px;
}

.adminpage .hidden {
    display: none;
}

.adminpage .image-modal button {
    background-color: antiquewhite;
    padding: 10px;
    font-weight: bold;
    color: black;
}


@media screen and (max-device-width: 1000px) {}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}