.terms-page {
  text-align: right;
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (max-device-width: 1000px) {
  .terms-page .back-button {
    display: flex;
  }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}