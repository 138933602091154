.fielddecorator div.field {
    padding: 20px;
}

.fielddecorator div.field dt {
    text-align: right;
    font-weight: bold;
}

.fielddecorator dd {
    margin-inline-start: 0px;
}

.fielddecorator .field dd {
    text-align: right;
    padding: 10px 0px 10px 0px;
}


@media screen and (max-device-width: 1000px) {}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}