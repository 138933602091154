.my-picture {
    margin-top: 20px;
    border: 7px solid #e38b7a;
    border-radius: 25px;
    width: 400px;
    height: 370px;
    box-shadow: inset 11px -9px 5px lightgrey, 11px -9px 5px lightgrey;
}

.my-picture img {
    width: 370px;
    height: 370px;
}

@media screen and (max-device-width: 1000px) {
    .my-picture {
        margin: auto;
        margin-bottom: 10px;
        border: 3px solid #e38b7a;
        border-radius: 15px;
        width: 200px;
        height: 170px;
    }

    .my-picture img {
        width: 170px;
        height: 170px;
    }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}