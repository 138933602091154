.home .main-content {
    background-color: rgb(241, 241, 241, 0.9);
    width: 36em;
    height: 42em;
    float: left;
    margin-left: 18em;
    margin-top: 2em;
    padding-top: 1em;
    box-shadow: 0px 0px 13px black;
    border-radius: 20px;
}

.home {
    height: 100vh;
    background-image: var(--bgImgUrl_modern);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    animation: chbg 15s infinite;
}

@keyframes chbg {
    0%,10%,20% {
        background-image: var(--bgImgUrl_modern);
    }

    33.3%,40%,53% {
        background-image: var(--bgImgUrl_luxury);
    }

    66.6%, 76%, 87% {
        background-image: var(--bgImgUrl_tropic);
    }
}

/* 

.home.first {
background-image: var(--bgImgUrl_modern);
}
.home.second {
background-image: var(--bgImgUrl_luxury);
}
.home.third {
background-image: var(--bgImgUrl_tropic);
} */

.home .mobile-cover {
    display: none;
}

.home .mobile-content {
    display: none;
}

@media screen and (max-device-width: 1000px) {
  .home {
    background-size: cover;
    background-position: left;
    background-image: none;
    animation: none;
    height: auto;
  }
  .home.first {
    background-image: none;
  }
  .home.second {
    background-image: none;
  }
  .home.third {
    background-image: none;
  }
  .home .mobile-cover {
    width: 100%;
    height: calc(1vw  * 70);
    background-image: var(--bgImgUrl_modern);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    animation: chbg 15s infinite;
    display: block;
    
  }
  .home .mobile-cover img{
    width: 100%;
    opacity: 0;
    background-image: var(--bgImgUrl_modern);
  }
  .home .main-content {
    margin: auto;
    width: 100%;
    border: none;
    float: none;
    margin-top: 0;
    height: fit-content;
    padding-bottom: 10px;
    border-radius: 0;
  }
  .home .mobile-content {
    display: block;
  }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {
    .home .main-content {
      width: 40em;
      height: 46em;
      margin-left: 36em;
    }
  }