.info-button {
    display: flex;
    width: fit-content;
    margin: auto;
    margin-bottom: 8px;
    padding-left: 32px;
}

.info-button .info-button-icon {
    background-image: url('/public/images/info-button.png');
    height: 45px;
    width: 45px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    position: relative;
    right: 25px;
    top: -15px;
}

.info-button .info-button-text {
    background-color: white;
    border: 5px solid transparent;
    border-radius: 12px;
    box-shadow: -4px 5px 0px #d2d2d2;
    padding: 3px;
    width: 130px;
    font-size: 1em;
    height: 16px;
}

.info-button .info-button-text a {
    color: black;
    text-decoration: none;
}

@media screen and (max-device-width: 1000px) {
    .info-button {
        margin-top: 30px;
    }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}