.squarelogo-container {
    margin-top: 2em;
    padding: 15px;
}
.squarelogo-container img {
  width: 275px;
  height: 275px;
}

@media screen and (max-device-width: 1000px) {
    .squarelogo-container {
        margin-top: 0em;
        padding: 0;
    }
    .squarelogo-container img {
      width: 175px;
      height: 175px;
    }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {

}