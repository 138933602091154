@keyframes chbg {
    0%,10%,20% {
        background-image: var(--bgImgUrl_modern);
    }

    33.3%,40%,53% {
        background-image: var(--bgImgUrl_luxury);
    }

    66.6%, 76%, 87% {
        background-image: var(--bgImgUrl_tropic);
    }
}

.quizlanding { 
    width: 80%;
    margin: auto;
    font-size: 1.2em;
}

.quizlanding .bottom-20 {
    margin-bottom: 20px;
}
.quizlanding .top {
    background-image: var(--bgImgUrl_tropic);
    background-repeat: no-repeat;
    background-size: cover;
    animation: chbg 15s infinite;
    height: calc(1vh * 100);
    background-position: right;
}
.quizlanding .top .landing-page-logo-container {
    margin: auto;
    padding-top: 20px;    
    max-width: 300px;
    margin-bottom: calc(10vh);
    width: 80%;
}
.quizlanding .landing-page-logo {
    margin: 0;
    clear: both;
    float: none;
    background-color: rgb(235, 234, 233, 0.73);
    border-radius: 20px;
}

.quizlanding .top .cta {
    background-color: rgba(255, 255, 255, 0.73);
    width: 80%;
    margin:auto;
    padding: 20px;
    margin-bottom: 50px;
    border-radius: 25px;
}

.quizlanding .cta button {
    background-color: #0A1A44;
    --no-such-font-size: 22px;
    height: 80px;
    border-radius: 40px;
    padding-right: 35px;
    max-width: 310px;
    padding-left: 35px;
    margin-top: 10px;
    width: fit-content;
    font-size: 1.1em;
}

.quizlanding .why {
    background-color: #BF939B;
    padding-top: 50px;
}
.quizlanding .why .cta {
    width: 70%;
    margin: auto;
}
.quizlanding .why .cta ul {
    text-align: center;
    list-style-type: none;
    padding: 0px;
}
.quizlanding .who {
    background-color: white;
    padding-top: 50px;
}
.quizlanding .who .cta {
    background-color: #410C2D;
    width: 80%;
    border-radius: 40px;
    color: white;
    text-align: right;
    margin: auto;
    padding: 40px;
    line-height: 40px;
    margin-bottom: 50px;
}
.quizlanding .who .cta .button-placement button {
    background-color: #E0A49A;
    max-width: fit-content;
    border-radius: 10px;
    color: black;
}
.quizlanding .who .cta .button-placement {
    margin: auto;
    width: 100%;
    text-align: center;
}
.quizlanding .about {
    background-color: #EBE8E8;
    padding-top: 50px;
}
.quizlanding .about .cta {
    padding-bottom: 60px;
}
.quizlanding .about .my-picture {
    border: 5px solid #0A1A44;
    border-radius: 200px;
    width: 400px;
    height: 400px;
    background-color: white;
    background-image: url('../../../public/images/aye.png');
    margin: auto;
    box-shadow: none;
    background-position: top;
    background-size: cover;
}
.quizlanding .about .my-picture img {
    display: none;
}

.quizlanding .about-text {
    background-color: #BF939B;
    padding-top: 50px;
    line-height: 38px;
}
.quizlanding .about-text .cta {
    padding: 0px 40px 40px 40px;
}

.quizlanding .how {
    background-color: #0A1A44;
    padding-top: 30px;
    padding-bottom: 30px;
}

.quizlanding .how .cta {
    background-color: #EBE8E8;
    max-width: 80%;
    width: calc(1vw * 70);
    margin: auto;
    border-radius: 36px;
    padding: 30px;
}
.quizlanding .how .cta .text {
    margin-bottom: 30px;
}

/** testimonials classes **/
.quizlanding .testimonials-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.quizlanding .testimonials {
    background-color: white;
    padding-top: 50px;
}

.quizlanding .why .cta .attribute-visuals {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
}
.quizlanding .why .cta .attribute-visuals .attribute {
    width: 30%;
    text-align: right;
    margin-bottom: 30px;
    padding: 0px 10px 0px 10px;
}
.quizlanding .why .cta .attribute-visual {
    width: 177px;
    height: 170px;
    margin: 0px 40px 20px 40px;
}

.quizlanding .why .cta .attribute-visual.progress {
    background-image: url('../../../public/images/quiz-landing/match-bars.png');
}
.quizlanding .why .cta .attribute-visual.question {
    background-image: url('../../../public/images/quiz-landing/question.png');
}
.quizlanding .why .cta .attribute-visual.design {
    background-image: url('../../../public/images/quiz-landing/interior-design.png');
}
.quizlanding .why .cta .attribute-visual.abc {
    background-image: url('../../../public/images/quiz-landing/abc.png');
}
.quizlanding .why .cta .attribute-visual.list {
    background-image: url('../../../public/images/quiz-landing/list.png');
}

@media screen and (max-device-width: 1000px) {
    .quizlanding {
        width: 100%;
    }
    .quizlanding .top {
        width: 100%;
        background-image: var(--bgImgUrl_modern);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .quizlanding .top .cta {
        width: 80%;
    }
    .quizlanding .why .cta {
        width: 80%;
    }
    .quizlanding .why .cta ul {
        text-align: right;
        list-style-type: disc;
    }
    .quizlanding .why .cta .attribute-visuals {
        display: block;
    }
    .quizlanding .why .cta .attribute-visuals .attribute {
        width: 100%;
    }
    .quizlanding .why .cta .attribute-visual {
        margin: 20px auto;
    }
    .quizlanding .about .my-picture {
        width: calc(1vw * 80);
        height: calc(1vw * 80);
    }

    .quizlanding .who .cta .button-placement { 
        width: 88%;
    }
    .quizlanding .who .cta .button-placement button { 
        padding: 10px;
        height: 3em;
    }
    .quizlanding .who .cta { 
        width: 85%;
        padding: 10px;
    }
    .testimonials {
        flex-direction: column;
    }

    .testimonials-wrapper .testimonial {
        width: 80%;
    }
    .quizlanding .cta button {
        margin: 10px auto;
    }

    .quizlanding .why .cta .attribute-visual {
        width: 130px;
        background-size: contain;
        background-repeat: no-repeat;
        height: 127px;
    }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {
    .quizlanding {
        width: 60%;
    }
}
