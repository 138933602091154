.stylingform {
  width: fit-content;
  max-width: 60%;
  margin: auto;
}

.stylingform hr {
  margin-bottom: 20px;
  border-top-style: dashed;
}

.stylingform div.checkboxes {
  text-align: right;
  flex-flow: row-reverse;
  justify-content: right;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.stylingform .logo-container {
  background-color: transparent;
  float: none;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0px;
}

.stylingform .section-buttons {
  margin-top: 20px;
}


@media screen and (max-device-width: 1000px) {
  .stylingform {
    max-width: 100%;
    margin: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .stylingform button, .stylingform input[type=submit] {
    margin: 0px 3px 0px 3px;
  }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {

  }