.footer {
    margin: auto;
    width: 100%;
    font-size: 10px;
    color: gray;
    margin-top: 20px;
}

.footer a,
.footer a:visited,
.footer a:hover {
    color: gray;
}

@media screen and (max-device-width: 1000px) {
    .footer {
        margin-top: 10px;
        position: relative;
    }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}