.Stylingquiz { 
    width: 80%;
    margin: auto;
    background-color: #EBE8E8;
    min-height: calc(1vh * 100);
}
.Stylingquiz .logo-container {
    margin: auto;
    max-width: 300px;
    width: 80%;
    float: none;
}
.Stylingquiz .landing-page-logo {
    margin: 0;
    clear: both;
    float: none;
    background-color: rgb(235, 234, 233, 0.73);
    border-radius: 20px;
}
.Stylingquiz .quiz-questions-menu {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 50%;
    margin: auto;
}
.Stylingquiz .quiz-questions-menu .question-link {
    color: black;
}
.Stylingquiz .quiz-questions-menu .question-link button {
    margin: 0px;
    font-size: 15px;
    padding: 0;
    border-radius: 15px;
    width: 30px;
    height: 30px;
}
.Stylingquiz .quiz-questions-menu .question-link.done-link button {
    width: fit-content;
    padding: 0px 5px 0px 5px;
}
/* .Stylingquiz .quiz-questions-menu .question-link.done-link > button:disabled {
    display: none;
} */
.Stylingquiz .quiz-questions-menu .question-link button.active {
    background-color: #410C2D;
}

/* .Stylingquiz .quiz-progress-text {
    position: absolute;
    margin-top: -32px;
    width: 80%;
    color: white;
    font-weight: bold;
}
.Stylingquiz .quiz-progress-bar {
    width: 80%;
    height: 3em;
    background-color: #E0A49A;
    border-radius: 1.5em;
    margin: auto;
}
.Stylingquiz .quiz-progress-bar .quiz-progress {
    height: 100%;
    background-color: #410C2D;
    border-radius: 1.5em;
    width: 0px;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-10 {
    width: 10%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-20 {
    width: 20%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-30 {
    width: 30%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-40 {
    width: 40%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-50 {
    width: 50%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-60 {
    width: 60%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-70 {
    width: 70%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-80 {
    width: 80%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-90 {
    width: 90%;
}
.Stylingquiz .quiz-progress-bar .quiz-progress-100 {
    width: 100%;
} */

.Stylingquiz .question .answers .answer {
    cursor: pointer;
}
.Stylingquiz .question-type-image-select .answers .image-answer {
    margin: auto 10px;
    margin-bottom: 20px;
    background-color: white;
    padding: 10px;
    box-shadow: 4px 4px 6px grey;
}

.Stylingquiz .question-type-text-select .answers .text-answer {
    margin: auto;
    width: 80%;
    text-align: right;
    padding-bottom: 10px;
}
.Stylingquiz .question-type-text-select .answers .text-answer input {
    margin: 0px;
    padding: 0px;
}
.Stylingquiz .question-type-image-select .answers {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    justify-content: center;
}
.Stylingquiz .question-type-image-select .answers .image-answer input { 
    display: block;
    margin: auto;
}

.Stylingquiz .question .answers .answer input { 
    border: 2px solid #E0A49A;
    /* border-color: #410C2D; */
    border-radius: 8px;
    appearance: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.Stylingquiz .answers .selected-answer input {
    background-color: #410C2D;
}

@keyframes removeQuestion{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        display: none;
        opacity: 0;
    }
}
.question-removal {
    animation-name: removeQuestion;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.Stylingquiz .answer-review {
    background-color: white;
    width: 80%;
    margin: auto;
    box-shadow: 4px 4px 5px grey;
    margin-bottom: 20px;
    padding: 10px 0px 10px 0px;
}
.Stylingquiz .answer-review img {
    width: 150px;
}
@media screen and (max-device-width: 1000px) {
    .Stylingquiz { 
        width: 100%;
        height: auto;
    }
    .Stylingquiz .question-type-image-select .answers .image-answer img {
        width: 115px;
    }
    .Stylingquiz .question-type-image-select .answers .image-answer[data-fieldid="646980166"] img,
    .Stylingquiz .question-type-image-select .answers .image-answer[data-fieldid="971311403"] img {
        width: 320px;
    }
    .Stylingquiz .question-type-image-select .answers .image-answer[data-fieldid="1798177106"] img {
        width: 220px;
    }
    /* .Stylingquiz .quiz-progress-text {
        width: 100%;
    } */

    .Stylingquiz .quiz-questions-menu {
        width: 100%;
        justify-content: center;
    }
    .Stylingquiz .quiz-questions-menu .question-link {
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media screen and (max-device-width: 280px) {
    .Stylingquiz .question-type-image-select .answers .image-answer[data-fieldid="971311403"] img {
        width: 220px;
    }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {

}
