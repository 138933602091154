.logo-container {
    background-color: #ebeae9;
    /* box-shadow: -7px 9px 4px rgb(179, 175, 171, 0.7); */
    float: right;
    margin-right: 15.2em;
    margin-top: 2em;
    padding: 15px;
    border-radius: 20px;
}

.logo-container img {
    width: 240px;
    height: 88px;
}

.logo-container.second-logo {
    box-shadow: none;
    background-color: transparent;
}

@media screen and (max-device-width: 1000px) {
    .logo-container {
        margin: auto;
        float: none;
        border-radius: 0;
    }

    .logo-container img {
        width: 179px;
        height: 66px;
    }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {
    .logo-container {
        margin-right: 34em;
    }

    .logo-container img {
        width: 280px;
        height: 103px;
    }
}