.getquizform {
    display: flex;
    justify-content: center;
}
.getquizform .field {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}
.getquizform .field label {
    padding-left: 20px;
}
.getquizform .field input {
    max-width: 300px;
    width: calc(1vw * 60);
    border: none;
    border-radius: 15px;
    height: 30px;
    background-color: white;
}

.getquizform .form-error {
    color: red;
    font-size: 0.8em;
}

@media screen and (max-device-width: 1000px) {

}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {

}
