.testimonial {
    width: 30%;
    margin-bottom: 20px;
    min-height: 20px;
    font-size: 20px;
}

.testimonial-text, .testimonial-names {
    border: 10px solid #410C2D;
    border-radius: 36px;
    margin-bottom: 20px;
    min-height: 20px;
    font-size: 20px;
}
.testimonial-names h2{
    margin: 10px;
}

.testimonial .testimonial-text {
    padding: 20px;
    text-align: right;
}

.testimonial:empty {
    display: none;
}

@media screen and (max-device-width: 1000px) {

}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {

}
