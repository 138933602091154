.checkboxdecorator {
    width: 160px;
}

.checkboxdecorator .container {
    display: block;
    position: relative;
    padding-right: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

/* Hide the browser's default checkbox */
.checkboxdecorator .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkboxdecorator .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: rgb(196, 196, 196);
}

.checkboxdecorator .checkmark.radio {
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.checkboxdecorator .container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkboxdecorator .container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkboxdecorator .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkboxdecorator .container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkboxdecorator .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


@media screen and (max-device-width: 1000px) {}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}