.stylingform2 {
  width: fit-content;
  max-width: 60%;
  margin: auto;
}

.stylingform2 hr {
  margin-bottom: 20px;
  border-top-style: dashed;
}

.stylingform2 div.checkboxes {
  text-align: right;
  flex-flow: row-reverse;
  justify-content: right;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.stylingform2 .logo-container {
  background-color: transparent;
  float: none;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0px;
}

.stylingform2 .section-buttons {
  margin-top: 20px;
}


.stylingform2-squarelogo-container {
  margin-top: 2em;
  padding: 15px;
}
.stylingform2-squarelogo-container img {
width: 275px;
height: 275px;
}


@media screen and (max-device-width: 1000px) {
  .stylingform2 {
    max-width: 100%;
    margin: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .stylingform2 button, .stylingform2 input[type=submit] {
    margin: 0px 3px 0px 3px;
  }
  .stylingform2-squarelogo-container {
    margin-top: 0em;
    padding: 0;
}
.stylingform2-squarelogo-container img {
  width: 175px;
  height: 175px;
}
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {

  }