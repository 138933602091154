.aboutpage .main-content {
  background-color: rgb(241, 241, 241, 0.9);
  width: 40em;
  height: 38em;
  padding-top: 1em;
  box-shadow: 6px -1px 5px lightgray;
  border-radius: 25px;
  background-color: #e38b7a;
  display: flex;
  align-items: center;
}

.aboutpage .leftrow h1 {
  margin-top: 20px;
}

.aboutpage {
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
}

.aboutpage .mobile-cover {
  display: none;
}

.aboutpage .mobile-content {
  display: none;
}

.aboutpage .back-button a {
  color: black;
}

.aboutpage .about-page-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.aboutpage .aboutpage-buttons .info-button-text,
.aboutpage .aboutpage-buttons .whatsapp-info-about-text {
  background-color: #e5eaf2;
}

@media screen and (max-device-width: 1000px) {
  .aboutpage {
    background-size: cover;
    background-position: left;
    background-image: none;
    animation: none;
  }

  .aboutpage.first {
    background-image: none;
  }

  .aboutpage.second {
    background-image: none;
  }

  .aboutpage.third {
    background-image: none;
  }

  .aboutpage .mobile-cover {
    width: 100%;
    background-image: var(--bgImgUrl_modern);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    animation: chbg 9s infinite;
    display: block;
    height: 277px;
  }

  .aboutpage .mobile-cover img {
    width: 100%;
    opacity: 0;
    background-image: var(--bgImgUrl_modern);
  }

  .aboutpage .main-content {
    margin: auto;
    width: 100%;
    border: none;
    float: none;
    margin-top: 0;
    height: fit-content;
    padding-bottom: 10px;
    border-radius: 0;
  }

  .aboutpage .mobile-content {
    display: block;
  }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {
  .aboutpage .main-content {
    width: 40em;
    height: 46em;
    margin-left: auto;
  }
}