.entry-display {
    margin-top: 20px;
}

.entry-display .image-modal {
    z-index: 100;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    padding-right: 100px;
    padding-left: 100px;
    background-color: rgb(128, 128, 128, 85%);
}

.entry-display .modal-image-holder {
    width: 80%;
    height: 1vw;
}

.entry-display .modal-image-holder img {
    width: 100%;
}

.entry-display .general-images img,
.entry-display div.answer-line img {
    cursor: zoom-in;
    margin-left: 10px;
    margin-top: 10px;
}

.entry-display span.question {
    display: inline-block;
    width: 300px;
    padding-left: 20px;
}

.entry-display div.answer-line {
    background-color: aliceblue;
    margin-bottom: 20px;
    padding: 10px;
}


@media screen and (max-device-width: 1000px) {}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}