.about-page {
  font-size: var(--textSizeSmall);
  text-align: right;
  padding: 0px 20px 0px 20px;
}

.about-paragraph {
  margin-bottom: 25px;
}

@media screen and (max-device-width: 1000px) {
  .about-page {
    font-size: var(--textSizeMed);
  }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}