.contact-form {
  display: flex;
  justify-content: space-around;
}

.contact-form .field {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.contact-form .field label,
.contact-form .field input {
  padding-left: 20px;
  border: none;
  font-size: 1em;
}

.contact-form .field input {
  font-family: 'Varela Round';
  width: 12em;
}

.contact-page {
  font-size: var(--textSizeMed);
  padding-right: 20px;
  padding-left: 20px;
}

.contact-title {
  padding-top: 2em;
  padding-bottom: 2em;
}

.contact-form .field button {
  font-size: 1em;
  background-color: white;
  border: none;
  border-radius: 12px;
  padding: 4px 12px;
  font-weight: bold;
  margin-top: 30px;
  color: #de9b8b;
  font-family: 'Varela Round';
}

.contact-form .contact-thankyou {
  width: 100%;
  color: var(--orangeText);
}

.contact-form .field-description {
  justify-content: flex-start;
  padding-right: 7em;
  font-size: 0.5em;
  margin-top: -20px
}

.contact-page-buttons {
  display: flex;
  margin-top: 100px;
  font-size: 1rem;
}

@media screen and (max-device-width: 1000px) {
  .contact-form .field input {
    width: 10em;
  }
  .contact-form .field-description {
    padding-right: 8em;
  }
  .contact-page-buttons {
    display: none;
  }
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {}