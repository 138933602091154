:root {
  --orangeTextActive: #de9b8b;
  --orangeText: #ebbeb3;
  --textSizeMed: 1.8em;
  --textSizeBig: 2.4em;
  --textSizeSmall: 1.5em;

  --bgImgUrl_modern: url('/public/images/modern-luxury-authentic-dining-room-interior-design.jpg');
  --bgImgUrl_luxury: url('/public/images/luxury-authentic-dining-room-interior-design.jpg');
  --bgImgUrl_tropic: url('/public/images/tropical-authentic-dining-room-interior-design-with-gallery-wall.jpg');
}

body::after{
  position:absolute; width:0; height:0; overflow:hidden; z-index:-1; 
  content:var(--bgImgUrl_modern) var(--bgImgUrl_luxury) var(--bgImgUrl_tropic);
}

input[type=text], textarea {
  background-color: rgb(243, 243, 243);
  width: 300px;
  line-height: 30px;
  border-style: solid;
  border-radius:6px;
}
textarea {
  height: 100px;
}
button {
  cursor: pointer;
}
button, input[type=submit], input[type=button] { 
  border: none; 
  padding: 10px; 
  background-color: var(--orangeText); 
  color: white; 
  font-weight: bold;
  margin: 0px 10px 0px 10px;
  border-radius: 6px;
  cursor: pointer;
}

button.active, input[type=submit].active, input[type=button].active {
  background-color: var(--orangeTextActive);
  cursor: pointer;
}

button:disabled, input[type=submit]:disabled, input[type=button]:disabled {
  background-color: lightgray;
  cursor:not-allowed;
}

button.active-section {
  background-color: var(--orangeTextActive);
  cursor: pointer;
}

.desktop-inline {
  display: inline;
}

.mobile-inline {
  display: none;
}
.App {
  font-family: 'Varela Round';
  text-align: center;
}
iframe {
  border: 0px;
}
.orange-text {
  color: var(--orangeText);
}
.underlined {
  text-decoration: underline;
}
.bold {
  font-weight: bold;
}
.space-top-20 {
  margin-top: 20px;
}
.back-button {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
}

.back-button a, .back-button a:visited, .back-button a:hover {
  color: var(--orangeText);
}

.underconstruction {
  display: none !important;
}

@media screen and (min-device-width: 2500px) and (min-device-height: 1080px) {
  :root {
    --orangeText: #de9b8b;
    --textSizeMed: 2.1em;
    --textSizeBig: 2.8em;
    --textSizeSmall: 1.8em;
  }
}

@media screen and (max-device-width: 1000px) {
  .desktop-inline {
    display: none;
  }
  .mobile-inline {
    display: inline;
  }
  .back-button {
    display: none;
  }
  :root {
    --orangeText: #de9b8b;
    --textSizeMed: 1.1em;
    --textSizeBig: 1.4em;
    --textSizeSmall: 0.7em;
  }
}